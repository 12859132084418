  .header {
    background-color: rgb(49,53,67);
    height: 190px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    display: flex;
    justify-content: space-between;
    

    img {
      height: 100%;
      display: block;
      width: 320px;
    }

    .img2 {
      object-fit: cover;
      height: 100%;
    }

    h2 {
      color: white;
      font-size: 46px;
      font-family: 'Bona Nova SC';
      font-weight: 400;
      font-style: normal;
    }

    a {
      text-align: center;
      text-decoration: none;
      opacity: 0.9;
      transition: opacity 0.1s ease-in-out;
      margin-bottom: 1rem;
    }
    a:hover {
      opacity: 1;
    }
    
  }
  
  @media (width < 960px)  {
    .header a h2 {
      // color: black;
      text-align: center;
      font-size: clamp(1.75rem, 5vw, 5rem);
    }

    .header a {
      margin: 0 auto;
      margin-bottom: 1rem;
    }

    .header img {
      display: none;
    }

  }