* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Calibri";
}

body {
  background-color: rgb(204,203,208);
  display: flex;
  flex-direction: column;
  padding-bottom: 0px;
}

html, body {
  height: 100%;
  margin: 0;
}

.App {
  max-width: 1100px;
  min-height: 500px;
  margin: 0 auto;
}

h2 {
  margin-top: 2rem;
    font-size: 32px;
}

.content-wrapper{
  max-width: 700px;
  margin: 0 auto;
}

#invisible-button {
  // display: none;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}

@media (width < 740px) {
  .content-wrapper > h1,
  .content-wrapper > h2 {
    margin-left: 1rem;
  }
} 