.pdf-viewer {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 100%;
    margin: 0 auto;
    padding: 20px;
    box-sizing: border-box;
  }
  
  .pdf-viewer button {
    margin: 10px;
    padding: 5px 10px;
  }
  
  .pdf-controls {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
  }
  
  .pdf-controls button {
    margin: 0 10px;
  }
  
  .pdf-controls p {
    margin: 0 10px;
  }
  
  .react-pdf__Page {
    max-width: 100%;
    overflow-x: auto;
  }
  
  .react-pdf__Page canvas {
    max-width: 100%;
    height: auto !important;
  }