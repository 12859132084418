* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Calibri";
}

body {
  background-color: rgb(204, 203, 208);
  display: flex;
  flex-direction: column;
  padding-bottom: 0px;
}

html, body {
  height: 100%;
  margin: 0;
}

.App {
  max-width: 1100px;
  min-height: 500px;
  margin: 0 auto;
}

h2 {
  margin-top: 2rem;
  font-size: 32px;
}

.content-wrapper {
  max-width: 700px;
  margin: 0 auto;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}

@media (width < 740px) {
  .content-wrapper > h1,
  .content-wrapper > h2 {
    margin-left: 1rem;
  }
}
.nav {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 55px;
  width: 100%;
  background-color: rgb(49, 53, 67);
  border-bottom: 1px solid rgba(255, 255, 255, 0.05);
  color: white;
  margin-top: 0.4rem;
  position: relative;
}
.nav .menu-icon {
  display: none;
  cursor: pointer;
  padding: 10px;
  position: absolute;
  right: 20px;
  top: 5px;
}
.nav .menu-icon .bar {
  width: 25px;
  height: 3px;
  background-color: white;
  margin: 5px 0;
}
.nav ul {
  list-style: none;
  display: flex;
  justify-content: space-between;
  margin-right: 0;
  width: 1100px;
  padding: 0;
}
.nav li {
  font-size: 22px;
}
.nav a:hover {
  border-bottom: 2px solid white;
}
.nav a {
  text-decoration: none;
  color: inherit;
}
@media (width < 1150px) {
  .nav li {
    margin: 0rem 0.75rem;
  }
}
@media (max-width: 900px) {
  .nav {
    z-index: 10;
  }
  .nav .menu-icon {
    display: block;
    top: 3px;
  }
  .nav ul {
    display: none;
    flex-direction: column;
    position: absolute;
    top: 55px;
    left: 0;
    width: 100%;
    background-color: rgb(49, 53, 67);
    padding: 20px 0;
  }
  .nav ul.show {
    display: flex;
  }
  .nav li {
    margin: 10px 0;
    text-align: center;
  }
}

.header {
  background-color: rgb(49, 53, 67);
  height: 190px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  display: flex;
  justify-content: space-between;
}
.header img {
  height: 100%;
  display: block;
  width: 320px;
}
.header .img2 {
  object-fit: cover;
  height: 100%;
}
.header h2 {
  color: white;
  font-size: 46px;
  font-family: "Bona Nova SC";
  font-weight: 400;
  font-style: normal;
}
.header a {
  text-align: center;
  text-decoration: none;
  opacity: 0.9;
  transition: opacity 0.1s ease-in-out;
  margin-bottom: 1rem;
}
.header a:hover {
  opacity: 1;
}

@media (width < 960px) {
  .header a h2 {
    text-align: center;
    font-size: clamp(1.75rem, 5vw, 5rem);
  }
  .header a {
    margin: 0 auto;
    margin-bottom: 1rem;
  }
  .header img {
    display: none;
  }
}
.contentCard {
  border: 1px solid black;
  width: 300px;
  margin: 1rem 0rem;
}
.contentCard section {
  padding: 0.5rem;
}
.contentCard h3 {
  margin-bottom: 1rem;
}
.contentCard p {
  margin-bottom: 1rem;
}
.contentCard .contentCard__image {
  width: 100%;
  height: 150px;
  background-color: brown;
  margin-bottom: 1rem;
}

.document-wrapper {
  border: 2px solid transparent;
  transition: border 0.3s ease;
  border-radius: 5px;
  display: flex;
  margin-bottom: 2rem;
  cursor: pointer;
  background-color: rgb(32, 40, 69);
  height: 125px;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  padding: 0.5rem;
  max-width: 700px;
  margin: 0 auto;
  margin-top: 2rem;
}
.document-wrapper .document-wrapper__image {
  height: 60px;
  width: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
}
.document-wrapper img {
  height: 100%;
}
.document-wrapper .document-wrapper__container {
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: white;
  color: white;
  background-color: rgb(32, 40, 69);
  padding: 1rem;
}
.document-wrapper section {
  color: white;
}
.document-wrapper .document-wrapper__image {
  color: white;
}

.document-wrapper:hover * {
  text-decoration: underline;
}

.document-wrapper:first-of-type {
  margin-top: 2rem;
}

@media (width < 740px) {
  .document-wrapper img {
    display: none;
  }
  .document-wrapper {
    margin: 2rem 1rem;
  }
  .document-wrapper__container h3 {
    font-size: clamp(0.75rem, 3.5vw, 5rem);
  }
}
@media (width < 400px) {
  .document-wrapper__container h3 {
    font-size: clamp(0.75rem, 5vw, 5rem);
  }
}
.nyheter .nyhet {
  border: 2px solid transparent;
  transition: border 0.3s ease;
  margin-top: 2rem;
  cursor: pointer;
  padding: 1rem;
  border-radius: 7px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
  background-color: rgb(32, 40, 69);
  height: 125px;
  display: block;
  text-decoration: none;
  color: black;
}
.nyheter .nyhet .nyhet__header h3 {
  margin-bottom: 0.75rem;
}
.nyheter .nyhet .nyhet__header .nyhet__header__date {
  font-style: italic;
  margin-bottom: 0.75rem;
  font-size: 0.9rem;
}
.nyheter .nyhet * {
  color: white;
}
.nyheter .nyhet:hover {
  border: 2px solid white;
}
.nyheter svg {
  font-size: 16px;
  margin-left: 0.5rem;
  margin-top: 0.25rem;
}

#nyheter {
  margin-top: 4rem;
  font-size: 32px;
}

.kontakt {
  margin-top: 1rem;
  padding: 1rem;
  border-radius: 7px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
  background-color: rgb(32, 40, 69);
  color: white;
}
.kontakt table {
  width: 100%;
  max-width: 650px;
}
.kontakt .kontakt__table_p {
  margin-top: 1rem;
}
.kontakt td {
  padding-right: 1rem;
}
.kontakt .kontakt--p {
  margin: 1rem 0rem;
}
.kontakt ul {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.kontakt li {
  margin-left: 2rem;
}
@media (max-width: 700px) {
  .kontakt table {
    width: 100%;
  }
  .kontakt tr {
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
  }
  .kontakt td {
    padding-right: 0;
  }
  .kontakt td:first-child {
    font-weight: bold;
    margin-bottom: 0.25rem;
  }
}

.henvendelser {
  font-family: Arial, sans-serif;
  background-color: rgb(32, 40, 69);
  color: white;
  padding: 1rem;
  border-radius: 7px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
}
.henvendelser .henvendelser__sub-heading {
  margin-bottom: 1.5rem;
}

.tr--margin-top td {
  padding-top: 1rem;
}

.henvendelser p {
  margin: 0 0 10px;
}

.henvendelser ul {
  list-style-type: disc;
  padding-left: 20px;
}

.henvendelser li {
  margin: 0 0 5px;
}

.henvendelser strong {
  font-weight: bold;
}

.hjem h1 {
  margin-bottom: 2rem;
}

@media (width < 1100px) {
  .kontakt,
  .henvendelser {
    border-radius: 0;
  }
  .hjem > h1,
  .hjem > h2 {
    margin-left: 1rem;
  }
}
#viewPdf {
  margin-top: 1rem;
  width: 100px;
  height: 30px;
  margin: 1 auto;
  margin-left: 44%;
}

.searchContainer {
  margin-top: 1.5rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-bottom: 2rem;
}
.searchContainer .searchContainer__search {
  display: flex;
  width: 420px;
}
.searchContainer .searchContainer__search input {
  flex-grow: 1;
}
.searchContainer .searchContainer__search label {
  margin-top: 0.3rem;
  margin-left: 0.1rem;
  flex-shrink: 0;
  color: rgb(40, 40, 40);
}
.searchContainer input {
  -webkit-appearance: none; /* For iOS and Safari */
  -moz-appearance: none; /* For Firefox */
  appearance: none;
  border-style: 1px solid grey;
  border-radius: 5px;
  height: 28px;
  font-size: 20px;
  padding: 0.2rem;
}
.searchContainer .searchContainer__ul {
  list-style: none;
  width: 100%;
  font-size: 20px;
  padding: 0.2rem;
  background-color: white;
}
.searchContainer .searchContainer__results {
  background-color: white;
  width: 420px;
  position: absolute;
  z-index: 1000; /* Ensure it's above other content */
  top: 100%; /* Position it right below the search input */
  max-height: 300px; /* Adjust based on your needs */
  overflow-y: auto;
  background: white; /* Or any color fitting your design */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
}
.searchContainer .searchContainer__results li {
  margin-top: 0.5rem;
  background-color: rgb(238, 238, 238);
  padding: 0.1rem 0.1rem;
}
.searchContainer .searchContainer__results li:hover {
  background-color: rgb(219, 219, 219);
}
.searchContainer .searchContainer__results h2 {
  margin-top: 0.5rem;
  margin-left: 0.25rem;
}

@media (width < 768px) {
  .searchContainer__search {
    width: 300px !important;
  }
}
@media (width < 470px) {
  .searchContainer__results {
    width: 100% !important;
  }
}
.details {
  background-color: white;
  border-radius: 5px;
  padding: 1rem;
  margin-top: 2rem;
}
.details h1 {
  font-size: 30px;
}
.details .details__p {
  display: block;
  margin-top: 1rem;
}
.details .details__section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.75rem;
}
.details .details__section--date {
  font-style: italic;
}

.footer {
  background-color: rgb(28, 30, 38);
  color: white;
  width: 100%;
  bottom: 0;
  min-height: 80px;
  margin-top: 3rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  padding: 0.25rem;
}
.footer__table-wrapper {
  flex: 1;
  min-width: 300px;
  padding: 0.5rem;
}
.footer table {
  width: 100%;
}
.footer td {
  padding: 0.25rem;
  vertical-align: top;
}
.footer td:first-child {
  white-space: nowrap;
  padding-right: 1rem;
}
@media (max-width: 700px) {
  .footer {
    flex-direction: column;
    height: auto;
  }
  .footer__table-wrapper {
    width: 100%;
  }
  .footer table, .footer tr, .footer td {
    display: block;
    width: 100%;
  }
  .footer td:first-child {
    font-weight: bold;
    padding-bottom: 0;
  }
  .footer td:last-child {
    padding-bottom: 0.5rem;
  }
}

.nokkelen section {
  margin-top: 2rem;
  padding: 1rem;
  border-radius: 7px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
  color: white;
  background-color: rgb(32, 40, 69);
}
.nokkelen section li {
  margin-bottom: 1rem;
  margin-left: 1rem;
}

#lovverk {
  border: 2px solid transparent;
  transition: border 0.3s ease;
  border-radius: 5px;
  display: flex;
  margin-bottom: 2rem;
  margin-top: 2rem;
  cursor: pointer;
  background-color: #202845;
  color: white;
  justify-content: center;
  align-items: center;
  padding: 0.5rem;
  font-size: larger;
  text-decoration: none;
}

#lovverk:hover {
  text-decoration: underline;
}

#soknader {
  margin: 2rem 0rem;
}

.info {
  background-color: rgb(32, 40, 69);
  border-radius: 5px;
  margin-bottom: 2rem;
  padding: 1rem;
  color: white;
}
.info ul {
  list-style: none;
}
.info ul li {
  margin-bottom: 2rem;
}

#info {
  margin-bottom: 2rem;
}

@media (width < 715px) {
  .info {
    border-radius: 0px !important;
  }
}
@media (width < 770px) {
  #info, #soknader {
    margin-left: 1rem;
  }
}
.pdf-viewer {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 100%;
  margin: 0 auto;
  padding: 20px;
  box-sizing: border-box;
}

.pdf-viewer button {
  margin: 10px;
  padding: 5px 10px;
}

.pdf-controls {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}

.pdf-controls button {
  margin: 0 10px;
}

.pdf-controls p {
  margin: 0 10px;
}

.react-pdf__Page {
  max-width: 100%;
  overflow-x: auto;
}

.react-pdf__Page canvas {
  max-width: 100%;
  height: auto !important;
}

