.nokkelen {

    section {
        margin-top: 2rem;
        padding: 1rem;
        border-radius: 7px;
        box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
        color: white;
        background-color: rgb(32,40,69);

       li {
            margin-bottom: 1rem;
            margin-left: 1rem;
        }
    }
}