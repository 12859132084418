.document-wrapper {
    border: 2px solid transparent;
    transition: border 0.3s ease; 
    border-radius: 5px;
    display: flex;
    margin-bottom: 2rem;
    cursor: pointer;
    background-color: rgb(32,40,69);
    height: 125px;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    padding: 0.5rem;
    max-width: 700px;
    margin: 0 auto;
    margin-top: 2rem;

    .document-wrapper__image {
        height: 60px;
        width: 60px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: black;
    }

    img {
        height: 100%;
    }

    .document-wrapper__container {
        width: 100%;
        display: flex;
        flex-direction: column;
        background-color: white;
        color: white;
        background-color: rgb(32,40,69);
        padding: 1rem;
    }

    section {
        color: white;
    }

    .document-wrapper__image {
        color: white;
    }
}

.document-wrapper:hover * {
    text-decoration: underline;
  }
.document-wrapper:first-of-type {
    margin-top: 2rem;
}

@media (width < 740px) {

    .document-wrapper img {
        display: none;
    }

    .document-wrapper {
        margin: 2rem 1rem;
    }

    .document-wrapper__container h3 {
        font-size: clamp(0.75rem, 3.5vw, 5rem);
    }
}

@media (width < 400px) {

    .document-wrapper__container h3 {
        font-size: clamp(0.75rem, 5vw, 5rem);
    }
}