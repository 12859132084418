.contentCard {
    border: 1px solid black;
    width: 300px;
    margin: 1rem 0rem;

    section {
        padding: 0.5rem;
    }

    h3 {
        margin-bottom: 1rem;
    }
    p {
        margin-bottom: 1rem;
    }

    .contentCard__image {
        width: 100%;
        height: 150px;
        background-color: brown;
        margin-bottom: 1rem;
    }
}