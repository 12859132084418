#lovverk {
    border: 2px solid transparent;
    transition: border 0.3s ease;
    border-radius: 5px;
    display: flex;
    margin-bottom: 2rem;
    margin-top: 2rem;
    cursor: pointer;
    background-color: #202845;
    color: white;
    justify-content: center;
    align-items: center;
    padding: 0.5rem;
    font-size: larger;
    text-decoration: none;
}

#lovverk:hover {
    text-decoration: underline;
}