.nyheter {




    .nyhet {
        border: 2px solid transparent;
        transition: border 0.3s ease; 
        margin-top: 2rem;
        cursor: pointer;
        padding: 1rem;
        border-radius: 7px;
        box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
        background-color: rgb(32, 40, 69);
        height: 125px;
        display: block;
        text-decoration: none;
        color: black;

        .nyhet__header {
            h3 {
                margin-bottom: 0.75rem;
            }
            .nyhet__header__date {
                font-style: italic;
                margin-bottom: 0.75rem;
                font-size: 0.9rem;
            }
        }

        * {
          color: white;
        }

    }

    .nyhet:hover {
        border: 2px solid white;
      }

      svg {
        font-size: 16px;
        margin-left: 0.5rem;
        margin-top: 0.25rem;
      }
}

#nyheter {
    margin-top: 4rem;
    font-size: 32px;
}

.kontakt {
  margin-top: 1rem;
  padding: 1rem;
  border-radius: 7px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
  background-color: rgb(32, 40, 69);
  color: white;
  
  table {
      width: 100%;
      max-width: 650px;
  }

  .kontakt__table_p {
    margin-top: 1rem;
  }

  td {
    padding-right: 1rem;
  }

  .kontakt--p {
      margin: 1rem 0rem;
  }

  ul {
      margin-top: 1rem;
      margin-bottom: 1rem;
  }
  li {
      margin-left: 2rem;
  }

  @media (max-width: 700px) {
      table {
          width: 100%;
      }

      tr {
          display: flex;
          flex-direction: column;
          margin-bottom: 1rem;
      }

      td {
          padding-right: 0;
      }

      td:first-child {
          font-weight: bold;
          margin-bottom: 0.25rem;
      }
  }
}

.henvendelser {
    font-family: Arial, sans-serif;
    background-color: rgb(32, 40, 69);
    color: white;
    padding: 1rem;
    border-radius: 7px;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);

    .henvendelser__sub-heading {
      margin-bottom: 1.5rem;
    }
  }

  .tr--margin-top td {
    padding-top: 1rem;
  }
  
  .henvendelser p {
    margin: 0 0 10px;
  }
  
  .henvendelser ul {
    list-style-type: disc;
    padding-left: 20px;
  }
  
  .henvendelser li {
    margin: 0 0 5px;
  }
  
  .henvendelser strong {
    font-weight: bold;
  }

  .hjem {
    h1 {
        margin-bottom: 2rem;
    }
  }

  @media (width < 1100px) {

    .kontakt,
    .henvendelser {
      border-radius: 0;
    }

    .hjem > h1,
    .hjem > h2 {
      margin-left: 1rem;
    }
  }

  #viewPdf {
    margin-top: 1rem;
    width: 100px;
    height: 30px;
    margin: 1 auto;
    margin-left: 44%;
  }